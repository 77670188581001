<script lang="ts">
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { scrollNavBarHandler } from '$lib/store/scrollNavBarHandler.store';
	import { getTranslate, T } from '@tolgee/svelte';
	import { page } from '$app/stores';
	import { clientSideSearch, type SearchResponse } from '$lib/search/search-client';
	import { desktopNavBarExpansionStore } from '$lib/store/desktopNavBarExpansion.store';
	import { onMount, tick } from 'svelte';
	import { createBreakpointStore } from '$lib/store/breakpoint.store';
	import { deduplicate } from '$lib/utils';
	import { resolveRoute } from '$app/paths';
	import { languageStore } from '$lib/store/language.store';
	import slug from 'slug';
	import Image from '../Image/Image.svelte';
	import type { Product } from '$lib/utils/search';

	const { t } = getTranslate();
	const isMobile = createBreakpointStore();

	let searchResult: SearchResponse<Product> | undefined;

	let isFocused = false;
	let isExpanded = false;
	let query = '';
	let debounceTimeout: number | undefined;
	let inputElement: HTMLInputElement | undefined;

	async function toggleSearchInput() {
		isExpanded = !isExpanded;
		desktopNavBarExpansionStore.setIsExpanded(false);
		if (isExpanded && inputElement) {
			await tick(); // Ensure the input is rendered before focusing
			inputElement?.focus();
		}
	}

	function closeExpandedSearch() {
		debounceTimeout = window.setTimeout(() => {
			isExpanded = false;
		}, 500);
	}

	function cancelCloseExpandedSearch() {
		if (debounceTimeout) {
			clearTimeout(debounceTimeout);
		}
	}

	$: hits = searchResult?.hits ?? [];
	$: autoSuggestions = deduplicate(
		hits
			.filter((s) => !!s.collections)
			.map((hit) => {
				const { _matchesPosition } = hit;
				if (!_matchesPosition || !_matchesPosition.parentTitle) {
					return '';
				}
				const singleStrings = _matchesPosition.parentTitle.map((match) => {
					const substring = hit.parentTitle.slice(match.start);
					let firstSpace = substring.indexOf(' ');
					if (firstSpace === -1) {
						firstSpace = substring.length;
					}
					const charBeforeSpace = substring.charAt(firstSpace - 1);
					if (charBeforeSpace === ',' || charBeforeSpace === '.') {
						firstSpace--;
					}
					return substring.slice(0, firstSpace);
				});
				return singleStrings.join(' ');
			})
			.filter((suggestion) => suggestion.length > 0)
	).slice(0, 4);

	$: if (query.length > 0) {
		clientSideSearch($languageStore.locale)
			.search<Product>(query, {
				attributesToRetrieve: [
					'parentTitle',
					'parentHandle',
					'imageUrls',
					'collections',
					'selectedOption',
					'sku'
				],
				limit: 10,
				showMatchesPosition: true
			})
			.then((response) => {
				searchResult = response;
			});
	}

	onMount(() => {
		function handleKeydown(event: KeyboardEvent) {
			// Check for Command + K (Mac) or Ctrl + K (Windows/Linux)
			if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
				event.preventDefault(); // Prevent default action of the key press
				toggleSearchInput();
			}
		}
		document.addEventListener('keydown', handleKeydown);

		return () => {
			document.removeEventListener('keydown', handleKeydown);
		};
	});
</script>

<form
	action={resolveRoute('/[locale]-[country]/search', {
		...$page.params
	})}
	on:submit={() => {
		sessionStorage.clear();
		isExpanded = !isExpanded;
		desktopNavBarExpansionStore.setIsExpanded(false);
	}}
	on:mouseleave={closeExpandedSearch}
	on:mouseenter={cancelCloseExpandedSearch}
	method="get"
	class="relative"
>
	<input type="submit" hidden />
	<button
		type="button"
		class="hidden md:flex items-center h-16"
		on:click|preventDefault={toggleSearchInput}
	>
		<div class="i-sprenger-search h-5 w-5" />
	</button>
	<label for="searchInputMobile" class="relative block md:hidden md:max-w-md w-full">
		<div
			class={classes(
				'absolute inset-y-0 left-0 flex items-center pl-3',
				!$scrollNavBarHandler.isColorfulBar ? 'text-[#27293759]' : 'text-[#FFFFFFA6]'
			)}
		>
			{#if query.length > 0}
				<button
					type="button"
					class="i-sprenger-x w-5 h-5"
					data-testid="clear-search"
					on:click={() => {
						query = '';
					}}
				></button>
			{:else}
				<span class="i-sprenger-search w-5 h-5" data-testid="search-icon"></span>
			{/if}
		</div>
		<input
			type="text"
			name="q"
			disabled={!$isMobile}
			id="searchInputMobile"
			class={classes(
				'search-bar h-10 block w-full lg:min-w-[18rem] 2xl:min-w-[26rem] pl-10 rounded-[4px] focus:border-accent-primary focus:border-b-2 focus:ring-0 focus:outline-none sm:text-p-14',
				$scrollNavBarHandler.isColorfulBar
					? 'bg-accent-primary border border-light-quaternary hover:border-light-tertiary text-white placeholder-light-secondary'
					: 'border border-dark-quaternary hover:border-dark-tertiary placeholder-dark-secondary'
			)}
			placeholder={$t('search.placeholder')}
			bind:value={query}
			on:focus={() => (isFocused = true)}
			on:blur={() => setTimeout(() => (isFocused = false), 150)}
		/>
	</label>
	{#if (autoSuggestions.length > 0 || hits.length > 0) && isFocused}
		<div class="absolute py-2 rounded-b-lg w-full bg-white md:hidden" role="none">
			<span class="ml-3 text-h-12 font-light py-0.5 text-gray-900">
				<T keyName="search.quickResults" />
			</span>
			{#each hits as hit, i}
				<a
					href={resolveRoute(
						`/[locale]-[country]/products/[branch]/[handle]?variantSKU=${hit.sku}`,
						{
							...$page.params,
							branch: slug(hit.parentTitle),
							handle: hit.parentHandle
						}
					)}
					class={classes(
						i > 4 ? 'hidden md:flex' : 'flex',
						'items-center px-4 py-2 cursor-pointer hover:bg-gray-100 focus:bg-gray-100 focus-within:bg-gray-100'
					)}
				>
					<div class="relative w-[48px]">
						<Image
							width={48}
							height={48}
							src={hit.imageUrls[0]}
							alt={hit.parentTitle}
							sizes="48px"
							class="rounded-full"
						/>
					</div>
					<div class="flex-1 ml-4">
						<div class="text-sm font-medium text-gray-900 line-clamp-2">
							{hit.parentTitle}
						</div>
					</div>
				</a>
			{/each}

			{#each autoSuggestions as suggestion, i}
				<div
					class="p-2 w-full hover:bg-gray-100 transition duration-200 flex flex-row items-center px-5 text-gray-900"
				>
					<a
						on:click={() => (query = suggestion)}
						href={resolveRoute(`/[locale]-[country]/search?q=${suggestion}`, {
							...$page.params
						})}
						class="flex flex-row items-center flex-1"
					>
						<div class="mr-1 i-sprenger-search w-4 h-4" />
						<div class="text-sm leading-5 font-medium line-clamp-1 flex-1">{suggestion}</div>
					</a>

					<button
						class="z-10"
						type="button"
						on:click={() => {
							query = suggestion;
						}}
					>
						<div class="i-sprenger-north-west-arrow w-4 h-4" />
					</button>
				</div>
			{/each}
		</div>
	{/if}

	<div
		role="region"
		tabindex="-1"
		on:mouseenter={cancelCloseExpandedSearch}
		class={classes(
			'fixed right-0 transition transform duration-600 md:grid grid-cols-2 w-full border-b-12 border-b-accent-primary hidden -z-10',
			isExpanded ? 'translate-y-0' : '-translate-y-[220%]'
		)}
	>
		<div
			class="relative flex flex-col gap-6 bg-accent-primary_02 overflow-y-auto max-h-screen px-5 lg:px-20 py-10 text-black"
		>
			<label for="searchInput" class="relative block w-full">
				<div class={classes('absolute inset-y-0 left-0 flex items-center pl-3 text-[#27293759]')}>
					{#if query.length > 0}
						<button
							type="button"
							class="i-sprenger-x w-5 h-5"
							data-testid="clear-search"
							on:click={() => {
								query = '';
							}}
						></button>
					{:else}
						<span class="i-sprenger-search w-5 h-5" data-testid="search-icon"></span>
					{/if}
				</div>
				<input
					bind:this={inputElement}
					type="text"
					name="q"
					disabled={$isMobile}
					id="searchInput"
					class={classes(
						'search-bar h-10 block w-full lg:min-w-[18rem] 2xl:min-w-[26rem] pl-10 rounded-[4px] focus:border-accent-primary focus:border-b-2 focus:ring-0 focus:outline-none sm:text-p-14 border border-dark-quaternary hover:border-dark-tertiary placeholder-dark-secondary'
					)}
					placeholder={$t('search.placeholder')}
					bind:value={query}
					on:focus={() => (isFocused = true)}
					on:blur={() => setTimeout(() => (isFocused = false), 150)}
				/>
			</label>
			<div class="flex flex-col gap-3">
				<p class="text-p-14 !font-bold">
					<T keyName="search.relatedSearches" />
				</p>
				<ul>
					{#each autoSuggestions as suggestion, i}
						<div
							class="p-2 w-full hover:bg-gray-100 transition duration-200 flex flex-row items-center px-5 text-gray-900"
						>
							<a
								on:click={() => (query = suggestion)}
								href={resolveRoute(`/[locale]-[country]/search?q=${suggestion}`, {
									...$page.params
								})}
								class="flex flex-row items-center flex-1"
							>
								<div class="mr-1 i-sprenger-search w-4 h-4" />
								<div class="text-sm leading-5 font-medium line-clamp-1 flex-1">{suggestion}</div>
							</a>

							<button
								class="z-10"
								type="button"
								on:click={() => {
									query = suggestion;
								}}
							>
								<div class="i-sprenger-north-west-arrow w-4 h-4" />
							</button>
						</div>
					{/each}
				</ul>
			</div>
		</div>
		<div class="flex flex-col gap-6 w-full py-12 px-6 lg:px-11 text-black bg-white">
			<h3>
				<T keyName="search.quickResults" />
			</h3>
			{#if autoSuggestions.length > 0 || hits.length > 0}
				<div class="rounded-lg w-full bg-white border" role="none">
					{#each hits as hit, i}
						<a
							href={resolveRoute(
								`/[locale]-[country]/products/[branch]/[handle]?variantSKU=${hit.sku}`,
								{
									...$page.params,
									branch: slug(hit.parentTitle),
									handle: hit.parentHandle
								}
							)}
							on:click={closeExpandedSearch}
							class={classes(
								i > 4 ? 'hidden md:flex' : 'flex',
								'items-center px-4 py-2 cursor-pointer hover:bg-gray-100 focus:bg-gray-100 focus-within:bg-gray-100'
							)}
						>
							<div class="relative w-[48px]">
								<Image
									width={48}
									height={48}
									src={hit.imageUrls[0]}
									alt={hit.parentTitle}
									sizes="48px"
									class="rounded-full"
								/>
							</div>
							<div class="flex-1 ml-4">
								<div class="text-sm font-medium text-gray-900 line-clamp-2">
									{hit.parentTitle}
								</div>
							</div>
						</a>
					{/each}
				</div>
			{/if}
		</div>
	</div>
</form>
