<script lang="ts">
	import { storyblokEditable } from '@storyblok/svelte';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import { browser } from '$app/environment';
	import { TwoProductsQueryStore } from '$houdini';
	import type { ContentBlock7Props } from '$lib/storyblok/types';
	import StoryblokImageOrVideo from '$lib/components/Image/StoryblokImageOrVideo.svelte';
	import ProductCardVertical from '$lib/components/Product/ProductCardVertical.svelte';
	import RichText from '$lib/components/Storyblok/RichText.svelte';
	import { createBreakpointStore } from '$lib/store/breakpoint.store';
	import { isNetworkError } from '$lib/utils';
	import { getImagePriorityBelowFold } from '$lib/utils/image';
	import { transformProductCollectionsToBranch } from '$lib/utils/product';

	export let blok: ContentBlock7Props;
	export let position: number = 0;
	$: isBelowFold = position > 0;
	const productData = new TwoProductsQueryStore();

	let retries = 0;
	const mobileStore = createBreakpointStore();

	$: isMobileViewport = $mobileStore;
	$: if (browser && retries < 3) {
		productData
			.fetch({
				variables: {
					firstHandle: blok.product_1?.split('/')?.[0],
					secondHandle: blok.product_2?.split('/')?.[0]
				}
			})
			.catch((e) => {
				if (isNetworkError(e)) {
					setTimeout(() => retries++, 2000 * (retries + 1));
					return;
				}
				throw e;
			});
	}
	$: productVariantSku1 = blok.product_1?.split('/')?.[1];
	$: productVariantSku2 = blok.product_2?.split('/')?.[1];
</script>

<div
	use:storyblokEditable={blok}
	class={classes(
		'relative grid w-full gap-4 py-11 md:py-16 box-x-wrapper',
		blok.image?.filename || blok.shopTheLook || blok.video_id
			? 'grid-cols-1 md:grid-cols-2'
			: 'grid-cols-1',
		blok.blue_background ? 'bg-accent-primary_005' : ''
	)}
>
	{#if blok.image?.filename || blok.shopTheLook || blok.video_id}
		<div
			class={classes(
				'w-full h-full aspect-square md:aspect-auto relative',
				blok.image_emphasis === 'left' ? 'order-last' : ''
			)}
		>
			<StoryblokImageOrVideo
				class={classes(
					'w-full h-full absolute',
					blok.fill_image ? 'object-contain' : 'object-cover'
				)}
				filename={blok.image?.filename}
				alt={blok.image?.alt ?? undefined}
				focus={blok.image?.focus}
				sizes={isMobileViewport ? '100vw' : '55vw'}
				parallax={blok.image_parallax}
				video_id={blok.video_id}
				autoplay={blok.autoplay}
				shopTheLook={blok.shopTheLook}
				title={blok.image?.title}
				{...getImagePriorityBelowFold(isBelowFold)}
			/>
		</div>
	{/if}
	<div class="flex flex-col justify-between flex-1 w-full gap-10" id={blok.id}>
		<div class="w-full grid grid-cols-6">
			{#if blok.headline}
				<h3 class="text-h-24 lg:text-h-72 col-span-6 hyphens-auto">{blok.headline}</h3>
			{/if}

			<p class="mt-4 text-p-16 col-span-6 lg:col-span-4">
				<RichText text={blok.text} />
			</p>
		</div>
		<div class="flex w-full md:mt-auto gap-6 mb-6">
			{#if $productData.data?.firstProduct}
				{@const product = $productData.data.firstProduct}
				<div class="flex-1">
					<ProductCardVertical
						{...product}
						sourceExtra="Content Block 7"
						sku={productVariantSku1 ?? ''}
						branch={transformProductCollectionsToBranch(product.collections)}
						imageProps={getImagePriorityBelowFold(isBelowFold)}
					/>
				</div>
			{/if}
			{#if $productData.data?.secondProduct}
				{@const product = $productData.data.secondProduct}
				<div class="flex-1">
					<ProductCardVertical
						{...product}
						sourceExtra="Content Block 7"
						sku={productVariantSku2 ?? ''}
						branch={transformProductCollectionsToBranch(product.collections)}
						imageProps={getImagePriorityBelowFold(isBelowFold)}
					/>
				</div>
			{/if}
		</div>
	</div>
</div>
