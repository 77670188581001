<script lang="ts">
	import UniqueSellingPoint from '$lib/components/Footer/UniqueSellingPoint.svelte';
	import { page } from '$app/stores';
	import Contact from '$lib/components/Footer/Contact.svelte';
	import PaymentMethods from '$lib/components/Footer/PaymentMethods.svelte';
	import FooterLinks from '$lib/components/Footer/FooterLinks.svelte';
	import NewsletterSignUp from '$lib/components/Footer/NewsletterSignUp.svelte';
</script>

<footer class="overflow-x-hidden" aria-labelledby="footer-heading">
	<div class="py-11 w-full bg-[#E8E9F6] grid md:grid-cols-2 lg:grid-cols-4 place-items-center">
		{#each $page.data.footer?.content?.items || [] as item}
			<UniqueSellingPoint {...item} />
		{/each}
	</div>
	<div class="flex flex-col bg-accent-primary text-white">
		<div
			class="flex md:flex-row flex-col-reverse md:justify-evenly justify-center md:items-center py-10"
		>
			<div
				class="h-18 w-18 mx-2 text-white i-sprenger-logo-small hidden md:block lg:hidden flex-none"
			></div>
			<div
				class="h-14 w-56 mx-2 text-white i-sprenger-logo-horizontal justify-self-start hidden lg:block"
			></div>
			<div class="flex-col gap-2 hidden md:flex">
				<Contact />
				<PaymentMethods class="mt-2" />
			</div>
			<div class="flex -mt-6 md:mt-0">
				<div class="h-14 w-14 ml-3 mr-4 i-sprenger-logo-small block md:hidden flex-none"></div>
				<div class={'flex flex-col md:mt-0 gap-6 w-full'}>
					<NewsletterSignUp />
					<PaymentMethods class="md:hidden" />
					<div class="block md:hidden">
						<Contact />
					</div>
				</div>
			</div>
		</div>
		<div class="pl-[5.05rem] md:pl-28 pr-3.5 md:px-[4.125rem]">
			<hr />
			<FooterLinks />
		</div>
	</div>
</footer>
